<template>
    <div>
        <nh-title></nh-title>
        <div style="display: flex">
            <div><nh-menu active-selected="PatientListForManager"></nh-menu></div>
            <div style="width: calc(100% - 49px);padding: 30px;">
            </div>
        </div>
    </div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhMenu from "@/components/patient/components/nh-menu";
export default {
    name: "PatientListForManager",
    components: {NhMenu, NhTitle}
}
</script>

<style scoped>

</style>